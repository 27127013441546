import config from "../initConfig"

function fetchHighScores() {

	const { urlConstructor } = (config)

	const URL = urlConstructor()
	
	/**Ugly patch for dubai in API*/

	return fetch(`${URL}/fetchHighScores.php`)
		.then((res) => res.json())
		.then((data) => data)
		.catch((error) => {
			console.log(error);
			return [];
		});
}
export default fetchHighScores;
