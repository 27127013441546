import React from 'react'
import config from '../../initConfig'
import './index.css'

const NavPanel = ({setView}) => {
const {hasLocations} = config
const locations = hasLocations()

    return (
        <div className='NavPanel'>
            <div className="btn-group dropright">
                <button type="button" className="btn btn-gameover dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="sr-only">View Boards</span>
                </button>
                <div className="dropdown-menu">
                    {locations && locations.map((loc ,ind) => (<button className="dropdown-item" key={ind} onClick={() => setView(prev => ({...prev ,location :loc}))}>{loc.toUpperCase()}</button>))}
                    <button className="dropdown-item"  onClick={() => setView(prev => ({...prev ,location : null}))}>HOME</button>
                </div>
            </div>
        </div>
    );
}

export default NavPanel;