function loadConfig() {
	let config;
	try {
		config = require("../app-config.json");
	} catch (error) {
		console.log(error);
		return null;
	}
	return config;
}
export default loadConfig;
