import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Team } from "..";
import getTeams from "../../api/getTeams";
import "./teams.css";

const dummyData = [
	{
		room_name: "ALICE",
		team_name: "TEST TEAM looooooooooooo",
		score: 100000,
	},
	{
		room_name: "LAMISION",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "ILLUMINATI",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "HORRORCIRCUS",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "ALADDIN",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "DRACULA",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "HAUNTEDMANSION",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "ALICE",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "ALICE",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "ALICE",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "ALICE",
		team_name: "TEST TEAM",
		score: 100000,
	},
	{
		room_name: "ALICE",
		team_name: "TEST TEAM",
		score: 100000,
	},
];

function Teams(props) {
	const { t } = useTranslation();
	const { location } = props;
	const [teams, setteams] = useState([]);
	
	const getTeamsData = async () => {
		const data = await getTeams();
		setteams(data);
	};

	useEffect(() => {
		getTeamsData();
		const t = setInterval(() => {
	
			getTeamsData();
		}, 1000);
		return () => {
			clearInterval(t);
		};
	}, []);
	return (
		<table className='teams-table'>
			<thead>
				<tr>
					<th>{t("room")}</th>
					<th className='team-name-h'>{t("team_name")}</th>
					<th>{t("escape_time")}</th>
				</tr>
			</thead>
			<tbody>{teams && teams.map((team) => <Team data={team} key={team.room_name} />)}</tbody>
			
		</table>
	);
}
export default Teams;
