function pingApiServer() {
	let URL = window.ENV.REACT_APP_URL_LEADERBOARD_SYSTEM ? window.ENV.REACT_APP_URL_LEADERBOARD_SYSTEM : process.env.REACT_APP_URL_LEADERBOARD_SYSTEM ;

	return fetch(`${URL}/ping.php`)
		.then((res) => true)
		.catch((error) => {
			console.log(error);
			return false;
		});
}
export default pingApiServer;
