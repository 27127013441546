import "./appVersion.css";
import config from "../../../package.json";
import appConfig from "../../initConfig"
function AppVersion() {
	const {locationConstructor} = appConfig
	return (
		<>
		<div className='app-version-wraper'>
			<p>v{config.version}</p>
		</div>
		<div className='app-location-wraper'>
			<p>{locationConstructor().toUpperCase()}</p>
		</div>
		
		</>
	);
}

export default AppVersion;
