import "./backgroundVideo.css";
import bgVideo from "../../assets/videos/bg_video.mp4";
function BackgroundVideo() {
	return (
		<div className='background-video-wraper'>
			<video src={bgVideo} autoPlay loop muted></video>
		</div>
	);
}

export default BackgroundVideo;
