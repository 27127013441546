import { useEffect, useState } from "react";
import calculateTime from "../../utils/calculateTime";
import getRoomLogo from "../../utils/getRoomLogo";
import {loadFallbackImage} from "../../utils/loadFallbackImages";
import "./team.css";

function Team({ data }) {	
	const [time, settime] = useState("");
	const [img, setimg] = useState(null);
	const [fallback, setfallback] = useState(loadFallbackImage().default);
	
	useEffect(() => {
		const timeString = calculateTime(data.score);
		settime(timeString);
		
		/**Data . Location is only for Genaral leader board */
		let img = getRoomLogo(data.room_name.toUpperCase() , data.location ); 
		img && setimg(img)
			
}, [data]); // Ugly Patch for refresh results
console.log({fallback})
	return (
		<tr>
			<td className='room-logo'>
				<img src={img || fallback} alt={`${data.room_name} logo`} />
			</td>
			<td className='team-name'>
				<div>{data.team_name}</div>
			</td>
			<td className='escape-time'>{time}</td>
		</tr>
	);
}
export default Team;
