export const loadFallbackImage = (roomName,loc) => {
	let img;
	try {
		img = require(`../assets/images/default.svg`)
	} catch (error) {
		console.log(error);
		return img;
	}
	return img;
}
