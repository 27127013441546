import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Team } from "..";
import getTeams from "../../api/getTeams";
import fetchHighScores from "../../api/fetchHighScores.js"
import './index.css'

const GeneralBoard = (props) => {
    const [teams, setTeams] = useState([]);
    const { t } = useTranslation();

    const fetchScores = async () => {
        let res = await fetchHighScores()

        if (!res.length > 1) return;

        res.map(item => ({ ...item, location: "default" }))
        setTeams(res)
    }

    const getTeamsData = async (location) => {
        const data = await getTeams(location);
        setTeams(data);
    };

    useEffect(() => {
        if (!props.location) {
            fetchScores()
            const t = setTimeout(async () => fetchScores(), 4000)
            return () => clearInterval(t)
        }
        //Write some unit tests here
        getTeamsData(props.location);
        const t = setInterval(() => { getTeamsData(props.location) }, 1000);
        return () => {
            clearInterval(t);
        };
    }, [props])

    return (
        <div className="general-board">
            <table className='teams-table'>
                <thead>
                    <tr>
                        <th>{t("room")}</th>
                        <th className='team-name-h'>{t("team_name")}</th>
                        <th>{t("escape_time")}</th>
                    </tr>
                </thead>
                <tbody>{teams && teams.map((team) => <Team data={team} key={team.room_name} />)}</tbody>

            </table>
        </div>
    );
}

export default GeneralBoard;