import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import loadConfig from "../utils/loadConfig";
const conf = loadConfig();
const resources = {};
conf.languages.forEach((lang) => {
	let translation;
	try {
		translation = require(`./locales/${lang}.json`);
		resources[lang] = { translation: translation };
	} catch (error) {
		console.log(error);
	}
});
i18n.use(initReactI18next).init({
	resources,
	lng: conf.default_language,
	fallbackLng: conf.default_language,
	debug: true,

	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
