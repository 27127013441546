
import { BackgroundVideo, Header, ServerError, Teams, HighScores, NavPanel, GeneralBoard } from "./components";
import AppVersion from "./components/appVersion/AppVersion";
import config from "./initConfig"
import { useState, useEffect } from 'react'

function App() {
	const { locationConstructor, getOnlineConfig } = config
	const initialState = {
		location: locationConstructor(),
		isAuth: false
	}
	const [state, setState] = useState(initialState)

	const o = async ( ) => {
		console.log(await getOnlineConfig())
	} 
	useEffect(() => {
		o()
	}, [])

	return (
		<div className='app'>
			<ServerError />
			<BackgroundVideo />

			<Header />
			{config.isGeneralLeaderboard() && <NavPanel setView={setState} />}
			{/* {config.isWeb() && <HighScores/> } */}
			{config.isGeneralLeaderboard() && <GeneralBoard location={state.location} />}
			{!config.isGeneralLeaderboard() && <Teams />}

			<AppVersion />
		</div>
	);
}

export default App;
