import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./localization/i18n-setup";
if (window && window.api) {
	// ELECTRON / PROD mode
	// call the Electron API to fetch the exposed ENV variable
	window.api
		.invoke("env-variables", "REACT_APP_URL_AHS_SYSTEM","REACT_APP_URL_LEADERBOARD_SYSTEM")
		.then((data) => {
			console.log("Fetched ENV variables", data);

			// set the ENV variables as global variables
			window.ENV = data;
		})
		.then(() => {
			// render the DOM
			renderDOM();
		});
} else {
	// no OS variables in this case
	window.ENV = {
		REACT_APP_URL_AHS_SYSTEM: "http://localhost/ahs_system",
	};
	// render the DOM
	renderDOM();
}

function renderDOM() {
	// render the React DOM
	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		document.getElementById("root")
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
