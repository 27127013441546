import config from "../initConfig"
const { locationConstructor } = config
let location = locationConstructor();
function getRoomLogo(roomName,loc) {
	let img;
	try {
		img = require(`../assets/images/${loc ? loc : location.toLowerCase()}/${roomName}.svg`)
	} catch (error) {
		console.log(error);
		return img;
	}
	return img;
}

export default getRoomLogo;
