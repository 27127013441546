import config from "../initConfig"

function getTeams(location) {

	const { urlConstructor, locationConstructor } = (config)

	const URL = urlConstructor()
	const LOCATION = location ? location : locationConstructor()

	/**Ugly patch for dubai in API*/

	return fetch(`${URL}/fetchTeams.php?location=${LOCATION}`)
		.then((res) => res.json())
		.then((data) => data)
		.catch((error) => {
			console.log(error);
			return [];
		});
}
export default getTeams;
