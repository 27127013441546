import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import pingApiServer from "../../api/pingApiServer";
import "./serverError.css";
import Offline from "../Offline/Offline"
function ServerError() {
	const { t } = useTranslation();
	const [gotError, setgotError] = useState(false);
	const pingServer = async () => {
		const gotResponse = await pingApiServer();
		setgotError(!gotResponse);
	};
	useEffect(() => {
		const t = setInterval(() => {
			pingServer();
		}, 1000);

		return () => {
			clearInterval(t);
		};
	}, []);



	return gotError ? <>
		<Offline />
		<div className='server-error'>{t("server_error") || "Server Error"}</div>
	</> : null;
}
export default ServerError;
