import "./header.css";
import logoImg from "../../assets/images/GO LOGO.svg";
import slideImg from "../../assets/images/SLIDE.svg";
import leaderboardImg from "../../assets/images/LEADERBOARD.svg";
function Header() {
	return (
		<div className='header'>
			<img src={logoImg} className='logo' />
			<img src={slideImg} className='slash' />
			<img src={leaderboardImg} className='leaderboard' />
		</div>
	);
}

export default Header;
